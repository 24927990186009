import { NodeStyleModel } from '../runtime/NodeModel'
import { StyleDeclarationBlock } from '../runtime/NodeModel'

export class CssEditor extends HTMLElement {
  editor: HTMLTextAreaElement
  constructor() {
    super()

    this.editor = document.createElement('textarea')
    this.attachShadow({ mode: 'open' })

    this.editor.style.height = '200px'
    this.editor.style.width = '100%'
    this.editor.style.boxSizing = 'border-box'
    this.editor.style.overflow = 'auto'

    this.editor.style.color = '#f5f5f5'
    this.editor.style.padding = '8px'
    this.editor.style.borderRadius = '4px'

    this.editor.style.lineHeight = '1.5'
    this.editor.style.background = '#222'
    this.editor.style.marginTop = '16px'
    this.editor.style.fontSize = '12px'
    this.editor.addEventListener('keypress', (e) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault()
        const detail = this.css
        this.dispatchEvent(
          new CustomEvent('update', {
            detail,
          }),
        )
      }
    })
    this.shadowRoot?.appendChild(this.editor)
  }

  set css(styleObject: StyleDeclarationBlock) {
    const textValue = Object.entries(styleObject)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n')
    this.editor.value = `${textValue}`
  }
  get css() {
    const styleObject: StyleDeclarationBlock = Object.fromEntries(
      this.editor.value
        .split('\n')
        .filter((line) => line !== '')
        .map((line) => line.split(':').map((l) => l.trim())),
    )
    return styleObject
  }
}

export class StyleEditor extends HTMLElement {
  baseEditor: CssEditor
  variants: HTMLElement[]
  _style?: NodeStyleModel
  constructor() {
    super()
    this.variants = []
    this.attachShadow({ mode: 'open' })
    this.baseEditor = document.createElement('css-editor') as CssEditor

    this.baseEditor.addEventListener('update', (e) => {
      this.dispatchEvent(
        new CustomEvent('update', {
          detail: {
            ...(e as CustomEvent).detail,
            variants: this._style?.variants,
            breakpoints: this._style?.breakpoints,
          },
        }),
      )
    })
    this.shadowRoot?.appendChild(this.baseEditor)
  }

  set styles(styleObject: NodeStyleModel) {
    if (typeof styleObject !== 'object' || styleObject === null) {
      return
    }
    this._style = styleObject
    const { variants, breakpoints, ...baseStyles } = styleObject
    this.baseEditor.css = baseStyles
    this.variants.forEach((variant) => variant.remove())
    variants?.forEach((variant) => {
      const container = document.createElement('div')
      const input = document.createElement('input')
      container.appendChild(input)
      input.value = variant.className ?? ''
      input.addEventListener('change', (e) => {
        this.dispatchEvent(
          new CustomEvent('update', {
            detail: {
              ...this.style,
              variants: variants.map((va) =>
                va === variant ? { ...va, className: input.value } : va,
              ),
            },
          }),
        )
      })
      const editor = document.createElement('css-editor') as CssEditor
      container.appendChild(editor)
      editor.css = variant.style
      editor.addEventListener('update', (e) => {
        this.dispatchEvent(
          new CustomEvent('update', {
            detail: {
              ...this.styles,
              variants: variants.map((va) =>
                va === variant
                  ? { ...variant, style: (e as CustomEvent).detail }
                  : va,
              ),
            },
          }),
        )
      })
      this.shadowRoot?.appendChild(container)
      this.variants.push(container)
    })
  }

  get styles() {
    return this._style ?? {}
  }
}
customElements.define('css-editor', CssEditor, {})
customElements.define('style-editor', StyleEditor, {})
