import React, { forwardRef } from 'react'
import { ComponentProps } from 'react'

const InputLabel = forwardRef<
  HTMLLabelElement,
  ComponentProps<'label'> & { isSet?: boolean }
>(({ style = {}, isSet = false, ...props }, ref) => (
  <label
    ref={ref}
    style={{
      fontSize: 12,
      padding: '0 8px',
      display: 'flex',
      alignItems: 'center',
      color: isSet ? 'var(--primary-300)' : 'var(--grey-300)',
      ...style,
    }}
    {...props}
  />
))

export default InputLabel
