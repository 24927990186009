import { styled } from '@stitches/react'
import React, { ComponentProps } from 'react'

export const Crumb = styled('li', {
  color: 'var(--grey-400)',
  fontSize: 12,
})

export const CrumbButton = styled('button', {
  padding: 0,
  fontSize: 12,
  background: 'transparent',
  color: 'var(--grey-400)',
  cursor: 'pointer',
  '&:hover': {
    color: 'var(--grey-200)',
  },
})

export const CrumbSeparator = styled('span', {
  fontSize: 12,
  margin: '0 4px',
})

export const Crumbs = styled('ul', {
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  padding: 0,
})
