import React, { HTMLAttributes, useState } from 'react'
import { OperationSelect } from './OperationSelect'
import {
  applyFormula,
  ValueOperation,
  Formula,
  FunctionArgument,
  FunctionOperation,
  RecordOperation,
  PathOperation,
  valueToString,
} from '../../runtime/formula/formula'
import { NodeData } from '../../runtime/ComponentModel'
import { OperationArgInput } from './OperationArgInput'
import { styled } from '@stitches/react'
import { entries, update } from 'lodash'
import InputGroup from './InputGroup'
import InputLabel from './InputLabel'
import StatefulInput from './StatefulInput'

export const Operation = (props: {
  operation: Formula
  path: string
  onChange: (expression?: Formula) => void

  data: NodeData
}) => {
  const { operation } = props

  if (operation.type === 'function') {
    return <FunctionOp {...props} operation={operation} />
  }
  if (operation.type === 'record') {
    return <RecordOp {...props} operation={operation} />
  }

  return (
    <StyledOperation data-node-path={props.path}>
      <OperationHeader
        style={
          operation.type === 'path'
            ? {
                color: 'var(--yellow-800)',
                backgroundColor: 'var(--yellow-300)',
              }
            : {
                color: 'var(--blue-800)',
                backgroundColor: 'var(--blue-300)',
              }
        }
        data-color={operation.type === 'path' ? 'orange' : 'blue'}
      >
        <OperationSelect
          operation={props.operation}
          onChange={props.onChange}
        />
      </OperationHeader>
      {operation.type === 'path' && <PathOp {...props} operation={operation} />}
      {operation.type === 'value' && typeof operation.value === 'number' && (
        <NumberOp {...props} operation={operation} />
      )}
      {operation.type === 'value' && typeof operation.value === 'string' && (
        <StringOp {...props} operation={operation} />
      )}
      {operation.type === 'value' && typeof operation.value === 'boolean' && (
        <BooleanOp {...props} operation={operation} />
      )}

      <OutputHandle
        style={{ top: 20 }}
        path={props.path}
        onClick={() =>
          props.onChange({
            type: 'function',
            name: 'ID',
            arguments: [
              {
                formula: props.operation,
              },
            ],
          })
        }
      />
    </StyledOperation>
  )
}

const Crumb = styled('button', {
  background: 'transparent',
  border: '1px solid transparent',
  padding: 0,
  display: 'inline-block',
  fontSize: '12px',
  color: 'var(--grey-400)',
  fontWeight: 'bold',
  cursor: 'pointer',
  '&:hover': {
    color: 'var(--grey-100)',
  },

  '&:focus': {
    borderColor: 'var(--primary-399)',
  },
})

const PathKey = styled('button', {
  border: '1px solid transparent',
  color: 'var(--grey-300)',
  height: '32px',
  width: '100%',
  textAlign: 'left',
  padding: ' 0 16px',
  display: 'block',
  fontSize: '12px',
  background: 'transparent',
  '&:focus': {
    borderColor: 'var(--primary-300)',
    outline: 'none',
  },
  '&[aria-selected="true"]': {
    background: 'var(--grey-500)',
  },
})

const PathOp = (props: {
  data: NodeData
  path: string
  operation: PathOperation
  onChange: (operation: Formula) => void
}) => {
  const result = applyFormula(props.operation, props.data)
  const [highlightedIndex, setHighlightedIndex] = useState(0)

  const objectKeys =
    typeof result === 'object' && result !== null
      ? Object.keys(result)
      : undefined

  return (
    <div
      style={{
        minHeight: 40,
      }}
    >
      {props.operation.path.length > 0 && (
        <div
          style={{
            padding: '8px 16px',
          }}
          tabIndex={0}
          onFocus={(e) => {
            const crumbs = e.target?.querySelectorAll('button')
            crumbs[crumbs.length - 2]?.focus()
          }}
        >
          <Crumb
            tabIndex={-1}
            onClick={(e) => {
              props.onChange({
                ...props.operation,
                path: [],
              })
              setHighlightedIndex(0)
              ;(e.target as any).parentElement?.nextElementSibling?.focus()
            }}
            onKeyDown={(e) => {
              switch (e.key) {
                case 'ArrowRight':
                  if (props.operation.path.length > 2) {
                    ;(e.target as any).nextElementSibling?.focus()
                  }
                  break
              }
            }}
          >
            Data
          </Crumb>

          {props.operation.path.map((key, i) => (
            <React.Fragment key={i}>
              <span
                style={{
                  margin: '0 8px',
                  color: 'var(--grey-400)',
                  fontSize: '12px',
                }}
              >
                /
              </span>
              <Crumb
                tabIndex={-1}
                disabled={i === props.operation.path.length - 1}
                onClick={(e) => {
                  props.onChange({
                    ...props.operation,
                    path: props.operation.path.slice(0, i + 1),
                  })
                  ;(e.target as any).parentElement?.nextElementSibling?.focus()
                }}
                onKeyDown={(e) => {
                  switch (e.key) {
                    case 'ArrowLeft':
                      ;(e.target as any).previousElementSibling?.focus()
                      break
                    case 'ArrowRight':
                      if (i < props.operation.path.length - 2) {
                        ;(e.target as any).nextElementSibling?.focus()
                      }
                      break
                  }
                }}
              >
                {key}
              </Crumb>
            </React.Fragment>
          ))}
        </div>
      )}
      <div
        style={{
          maxHeight: 300,
          overflowY: 'auto',
        }}
        onScroll={(e) => e.stopPropagation()}
        tabIndex={-1}
        onFocus={(e) => {
          setTimeout(() => {
            const button = e.target.querySelector('button')
            button?.focus()
          }, 20)
        }}
      >
        {objectKeys && objectKeys.length > 0 ? (
          objectKeys.map((key, index) => (
            <PathKey
              key={key}
              aria-selected={index === highlightedIndex}
              tabIndex={index === highlightedIndex ? 0 : -1}
              onClick={(e) => {
                props.onChange({
                  ...props.operation,
                  path: [...props.operation.path, key],
                })
                ;(e.target as any).parentElement?.focus()
              }}
              onMouseOver={() => setHighlightedIndex(index)}
              onFocus={() => setHighlightedIndex(index)}
              onKeyDown={(e) => {
                switch (e.key) {
                  case 'ArrowDown':
                    ;(e.target as any).nextElementSibling?.focus()
                    break
                  case 'ArrowUp':
                    ;(e.target as any).previousElementSibling?.focus()
                    break
                }
              }}
            >
              {key}
            </PathKey>
          ))
        ) : (
          <OperationSection>
            {objectKeys ? (
              <span style={{ color: 'var(--grey-500)' }}>Empty</span>
            ) : (
              valueToString(result)
            )}
          </OperationSection>
        )}
      </div>
    </div>
  )
}

const Input = styled('input', {
  display: 'block',
  background: 'var(--grey-500)',
  color: 'var(--grey-200)',
  borderRadius: 4,
  height: 24,
  padding: '0 8px',
  border: '1px solid transparent',
  '&:focus': {
    borderColor: 'var(--primary-300)',
  },
})

const NumberOp = (props: {
  path: string
  operation: ValueOperation
  onChange: (operation: Formula) => void
}) => {
  return (
    <OperationSection>
      <Input
        value={Number(props.operation.value)}
        onChange={(e) =>
          props.onChange({
            ...props.operation,
            value: Number(e.target.value),
          })
        }
        onFocus={(e) => e.target.select()}
        onKeyDown={(e) => {
          switch (e.key) {
            case 'ArrowUp':
              e.preventDefault()
              props.onChange({
                ...props.operation,
                value:
                  Number(props.operation.value) +
                  (e.shiftKey ? 10 : e.altKey ? 0.1 : 1),
              })
              break
            case 'ArrowDown':
              e.preventDefault()
              props.onChange({
                ...props.operation,
                value:
                  Number(props.operation.value) -
                  (e.shiftKey ? 10 : e.altKey ? 0.1 : 1),
              })
              break
          }
        }}
      />
    </OperationSection>
  )
}

const StringOp = (props: {
  path: string
  operation: ValueOperation
  onChange: (expression: Formula) => void
}) => {
  return (
    <OperationSection>
      <Input
        value={String(props.operation.value)}
        onChange={(e) =>
          props.onChange({
            ...props.operation,
            value: e.target.value,
          })
        }
      />
    </OperationSection>
  )
}
const BooleanOp = (props: {
  path: string
  operation: ValueOperation
  onChange: (operation: Formula) => void
}) => {
  return (
    <OperationSection>
      <select
        value={props.operation.value ? 'True' : 'False'}
        onChange={(e) =>
          props.onChange({
            ...props.operation,
            value: e.target.value === 'True',
          })
        }
      >
        <option>True</option>
        <option>False</option>
      </select>
    </OperationSection>
  )
}
const FunctionOp = (props: {
  data: NodeData
  path: string
  operation: FunctionOperation
  onChange: (operation?: Formula) => void
}) => {
  const updateArg = (i: number, arg: FunctionArgument) => {
    props.onChange({
      ...props.operation,
      arguments: [
        ...props.operation.arguments.slice(0, i),
        arg,
        ...props.operation.arguments.slice(i + 1),
      ],
    })
  }

  return (
    <OperationGroup>
      <OperationChildren>
        {props.operation.arguments.map((arg, i) => {
          if (arg.formula.type === 'value') {
            return null
          }
          return (
            <Operation
              key={i}
              operation={arg.formula}
              path={`${props.path}.${i}`}
              data={
                props.operation.type === 'function'
                  ? window.toddle.getArgumentInputData(
                      props.operation.name,
                      props.operation.arguments.map((arg) =>
                        arg.formula
                          ? applyFormula(arg.formula, props.data)
                          : null,
                      ),
                      i,
                      props.data,
                    ) ?? props.data
                  : props.data
              }
              onChange={(formula) =>
                props.onChange({
                  ...props.operation,
                  arguments: [
                    ...props.operation.arguments.slice(0, i),
                    {
                      ...arg,
                      formula: formula ?? { type: 'value', value: null },
                    },
                    ...props.operation.arguments.slice(i + 1),
                  ],
                })
              }
            />
          )
        })}
      </OperationChildren>
      <StyledOperation data-node-path={props.path}>
        <OperationHeader
          style={{
            color: 'var(--green-800)',
            backgroundColor: 'var(--green-300)',
          }}
        >
          <OperationSelect
            operation={props.operation}
            onChange={(operation) => {
              if (
                (operation.type === 'function' && operation.name === 'ID') ||
                (operation.type === 'value' && operation.value === null)
              ) {
                props.onChange(
                  props.operation.arguments[0]?.formula ?? {
                    type: 'value',
                    value: null,
                  },
                )
                return
              }
              if (operation.type === 'function') {
                const firstArg = props.operation.arguments[0]
                props.onChange({
                  ...operation,
                  arguments:
                    firstArg &&
                    !(
                      firstArg.formula.type === 'value' &&
                      firstArg.formula.value === null
                    )
                      ? [
                          {
                            ...operation.arguments[0],
                            formula: firstArg.formula,
                          },
                          ...operation.arguments.slice(1),
                        ]
                      : operation.arguments,
                })
                return
              }
              props.onChange(operation)
            }}
          />
        </OperationHeader>
        {props.operation.arguments.map((arg, i) => (
          <OperationSection key={i}>
            <InputHandle
              path={`${props.path}.${i}`}
              onClick={() => {
                updateArg(i, {
                  ...arg,
                  formula: {
                    type: 'function',
                    name: 'ID',
                    arguments: [
                      {
                        formula: { type: 'value', value: null },
                      },
                    ],
                  },
                })
              }}
            />
            <OperationArgInput
              index={i}
              formula={arg.formula}
              name={arg.name ?? String(i)}
              onChange={(formula) => {
                updateArg(i, { ...arg, formula })
              }}
              data={props.data}
            />
          </OperationSection>
        ))}

        {props.operation.variableArguments && (
          <button
            onClick={() =>
              props.onChange({
                ...props.operation,
                arguments: [
                  ...props.operation.arguments,
                  props.operation.arguments[
                    props.operation.arguments.length - 1
                  ] ?? { formula: { type: 'value', value: null } },
                ],
              })
            }
          >
            add
          </button>
        )}
        <OutputHandle
          style={{ top: 20 }}
          path={props.path}
          onClick={() =>
            props.onChange({
              type: 'function',
              name: 'ID',
              arguments: [
                {
                  formula: props.operation,
                },
              ],
            })
          }
        />
      </StyledOperation>
    </OperationGroup>
  )
}
const RecordOp = (props: {
  data: NodeData
  path: string
  operation: RecordOperation
  onChange: (operation?: Formula) => void
}) => {
  const updateEntry = (i: number, entry: FunctionArgument) => {
    props.onChange({
      ...props.operation,
      entries: [
        ...props.operation.entries.slice(0, i),
        entry,
        ...props.operation.entries.slice(i + 1),
      ],
    })
  }

  return (
    <OperationGroup>
      <OperationChildren>
        {props.operation.entries.map((entry, i) => {
          if (entry.formula.type === 'value') {
            return null
          }
          return (
            <Operation
              key={i}
              operation={entry.formula}
              path={`${props.path}.${i}`}
              data={props.data}
              onChange={(formula) =>
                updateEntry(i, {
                  ...entry,
                  formula: formula ?? { type: 'value', value: null },
                })
              }
            />
          )
        })}
      </OperationChildren>
      <StyledOperation data-node-path={props.path}>
        <OperationHeader
          style={{
            color: 'var(--green-800)',
            backgroundColor: 'var(--green-300)',
          }}
        >
          <OperationSelect
            operation={props.operation}
            onChange={(operation) => {
              if (
                (operation.type === 'function' && operation.name === 'ID') ||
                (operation.type === 'value' && operation.value === null)
              ) {
                props.onChange({
                  type: 'value',
                  value: null,
                })
                return
              }
              if (operation.type === 'function') {
                props.onChange(operation)
                return
              }
              props.onChange(operation)
            }}
          />
        </OperationHeader>
        {props.operation.entries.map((entry, i) => (
          <OperationSection key={i}>
            <InputHandle
              path={`${props.path}.${i}`}
              onClick={() => {
                updateEntry(i, {
                  ...entry,
                  formula: {
                    type: 'function',
                    name: 'ID',
                    arguments: [
                      {
                        formula: { type: 'path', path: [] },
                      },
                    ],
                  },
                })
              }}
            />
            <InputGroup style={{ marginBottom: 4 }}>
              <InputLabel>Key</InputLabel>
              <StatefulInput
                value={entry.name}
                onChange={(name) => {
                  if (name !== '') {
                    updateEntry(i, { ...entry, name })
                    return true
                  }
                  return false
                }}
              />
            </InputGroup>
            <OperationArgInput
              index={i}
              formula={entry.formula}
              onChange={(formula) => {
                updateEntry(i, { ...entry, formula })
              }}
              data={props.data}
            />
          </OperationSection>
        ))}

        <button
          onClick={() =>
            props.onChange({
              ...props.operation,
              entries: [
                ...props.operation.entries,
                { name: '_key', formula: { type: 'value', value: '_value' } },
              ],
            })
          }
        >
          add
        </button>

        <OutputHandle
          style={{ top: 20 }}
          path={props.path}
          onClick={() =>
            props.onChange({
              type: 'function',
              name: 'ID',
              arguments: [
                {
                  formula: props.operation,
                },
              ],
            })
          }
        />
      </StyledOperation>
    </OperationGroup>
  )
}

export const ResultNode = (props: {
  data: NodeData
  path: string
  operation: Formula
  onChange: (operation?: Formula) => void
}) => {
  return (
    <OperationGroup>
      <OperationChildren>
        <Operation {...props} />
      </OperationChildren>
      <StyledOperation data-node-path={props.path}>
        <p style={{ padding: '8px 16px', textAlign: 'right' }}>
          {valueToString(applyFormula(props.operation, props.data))}
        </p>
        <InputHandle
          path={`0`}
          onClick={() => {
            props.onChange({
              type: 'function',
              name: 'ID',
              arguments: [
                {
                  formula: props.operation,
                },
              ],
            })
          }}
        />
      </StyledOperation>
    </OperationGroup>
  )
}

const OperationChildren = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  marginRight: 32,
  alignItems: 'end',
})

const OperationGroup = styled('div', {
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
})
const StyledOperation = styled('div', {
  position: 'relative',
  background: 'var(--grey-600)',
  borderRadius: 4,
  flexGrow: 0,
  flexShrink: 0,
  color: 'var(--grey-300)',
  width: 260,
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.4)',
})

const OperationHeader = styled('div', {
  position: 'relative',
  background: 'var(--grey-700)',
  borderTopRightRadius: 4,
  borderTopLeftRadius: 4,
  display: 'grid',
  alignContent: 'center',
  color: 'var(--grey-200)',
})

const OperationSection = styled('div', {
  position: 'relative',
  padding: '8px 16px',
  color: 'var(--grey-200)',
  '&[aria-selected="true"]': {
    background: 'var(--grey-700)',
  },
})

const StyledHandle = styled('button', {
  padding: 0,
  display: 'block',
  height: 12,
  width: 12,
  position: 'absolute',
  borderRadius: 999,
  background: 'var(--grey-400)',
  top: '50%',
  transition: 'all 300ms',
  border: '1px solid transparent',
  transformOrigin: 'center',
  '&:focus': {
    width: 24,
    height: 24,
    borderColor: 'var(--primary-300)',
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.4)',
  },
  '&[data-node-input]': {
    left: 0,
    transform: 'translate(-50%, -50%)',
  },
  '&[data-node-output]': {
    right: 0,
    transform: 'translate(50%, -50%)',
  },
})

export const InputHandle = (
  props: HTMLAttributes<HTMLButtonElement> & { path: string },
) => {
  const { path, ...buttonProps } = props

  return <StyledHandle data-node-input={path} {...buttonProps} />
}

export const OutputHandle = (
  props: HTMLAttributes<HTMLButtonElement> & { path: string },
) => {
  const { path, ...buttonProps } = props
  return <StyledHandle data-node-output={path} {...buttonProps} />
}
