import * as Radix from '@radix-ui/react-dropdown-menu'
import { styled } from '@stitches/react'

export const Dropdown = Radix.Root
export const DropdownTrigger = Radix.Trigger

export const DropdownContent = styled(Radix.Content, {
  minWidth: 130,
  background: 'var(--grey-700)',
  borderRadius: 4,
  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.4)',
})

export const DropdownItem = styled(Radix.Item, {
  padding: '4px 8px',
  cursor: 'default',
  '&:focus': {
    background: 'var(--grey-600)',
    color: 'var(--grey-200)',
  },
})
export const DropdownArrow = styled(Radix.Arrow, {
  fill: 'var(--grey-700)',
})
