import { styled } from '@stitches/react'
import React, { ComponentProps, forwardRef } from 'react'

export const InputGroup = styled('div', {
  alignItems: 'center',
  borderRadius: 4,
  border: '1px solid transparent',
  background: 'var(--grey-700)',
  display: 'flex',
  height: 32,
  position: 'relative',
  '&:hover': {
    borderColor: 'var(--grey-800)',
    '&:focus-within': {
      borderColor: 'var(--primary-300)',
    },
  },
  '&:focus-within': {
    borderColor: 'var(--primary-300)',
  },
})

export default InputGroup
