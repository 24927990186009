import { styled } from '@stitches/react'
import React, { InputHTMLAttributes, forwardRef } from 'react'

const Input = forwardRef<
  HTMLInputElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange: (value: string) => void
  }
>(({ onChange, className = '', ...props }, ref) => {
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    props.onKeyDown?.(e)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    onChange(e.target.value)
  }
  return (
    <StyledInput
      {...props}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      ref={ref}
    />
  )
})

const StyledInput = styled('input', {
  background: 'transparent',
  border: 'none',
  flex: 1,
  padding: 0,
  color: 'var(--grey-100)',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  '&:last-child': {
    paddingRight: 8,
  },
  '&:first-child': {
    paddingLeft: 8,
  },
  '&:focus': {
    outline: 'none',
  },
})
export default Input
