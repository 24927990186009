import React, { useEffect, useRef, useState } from 'react'
import { Formula, FunctionOperation } from '../../runtime/formula/formula'
import { capitalize, clamp } from 'lodash'
import { styled } from '@stitches/react'

export type OperationSelectProps = {
  operation?: Formula
  onChange: (operation: Formula) => void
}

const getOperationName = (op: Formula): string => {
  switch (op.type) {
    case 'value':
      return capitalize(op.value === null ? 'null' : typeof op.value)
    case 'function':
      return op.name
    case 'record':
      return 'Record'
    case 'path':
      return 'Data'
  }
}

export const OperationSelect = (props: OperationSelectProps) => {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [highlightedIndex, setHighlightedIndex] = useState(0)

  const [isOpen, setIsOpen] = useState(
    props.operation?.type === 'function' && props.operation.name === 'ID',
  )

  const [functionOperations, setFunctionOperations] = useState<{
    data: FunctionOperation[] | null
    isLoading: boolean
    error: Error[] | null
  }>({
    data: null,
    error: null,
    isLoading: false,
  })

  useEffect(() => {
    fetch('https://toddle.graphcdn.app', {
      method: 'POST',
      headers: {
        'x-hasura-admin-secret': 'wj75DVgisfBanV4',
      },
      body: JSON.stringify({
        query: `
          query getPlugins {
            Plugins {
              id
              name
              version
              formulas {
                name
                arguments
                variableArguments
              }
            }
          }
      `,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        return Promise.reject(res.text())
      })
      .then((data) => {
        if (data.errors) {
          return Promise.reject(data.errors)
        }
        setFunctionOperations({
          data:
            data.data.Plugins?.flatMap((plugin: any) =>
              plugin.formulas.map((func: any) => ({
                ...func,
                type: 'function',
              })),
            ) ?? [],
          isLoading: false,
          error: null,
        })
      })
      .catch((error) =>
        setFunctionOperations({
          data: null,
          isLoading: false,
          error,
        }),
      )
  }, [])

  const operations: Formula[] = [
    {
      type: 'value',
      value: null,
    },
    {
      type: 'path',
      path: [],
    },
    {
      type: 'record',
      entries: [],
    },
    ...(functionOperations.data ?? []),
  ]

  useEffect(() => {
    if (props.operation?.type === 'function' && props.operation.name === 'ID') {
      setIsOpen(true)
    }
  }, [props.operation])

  const filteredOperations = operations.filter((op) => {
    switch (op.type) {
      case 'value': {
        const type = op.value === null ? 'null' : typeof op.value
        return type.includes(inputValue.toLocaleLowerCase())
      }
      case 'function':
        return op.name.includes(inputValue.toLocaleUpperCase())
      case 'path':
        return 'data'.includes(inputValue.toLocaleLowerCase())
      case 'record':
        return 'record'.includes(inputValue.toLocaleLowerCase())
    }
  })

  useEffect(() => setHighlightedIndex(0), [inputValue])

  useEffect(() => {
    if (!isOpen) {
      return
    }
    const onDocumentClick = () => {
      // isOpen && setIsOpen(false);
    }

    document.body.addEventListener('click', (e) => {
      onDocumentClick()
    })
    document.addEventListener('blur', () => {
      onDocumentClick()
    })
    return () => {
      document.body.removeEventListener('click', onDocumentClick)
      document.removeEventListener('blur', onDocumentClick)
    }
  }, [isOpen])

  return (
    <div tabIndex={-1}>
      <SelectTrigger
        ref={buttonRef}
        data-operation-type={props.operation?.type}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(true)
          setInputValue('')
        }}
      >
        {props.operation ? getOperationName(props.operation) : ''}
      </SelectTrigger>

      {isOpen && (
        <SelectContent
          tabIndex={-1}
          onWheelCapture={(e: any) => {
            e.stopPropagation()
          }}
          onBlur={(e) => {
            setTimeout(() => setIsOpen(false), 100)
          }}
        >
          <SelectInput
            autoFocus={true}
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value ?? '')}
            onKeyDown={(e) => {
              switch (e.key) {
                case 'ArrowDown':
                  return setHighlightedIndex((index) =>
                    clamp(index + 1, 0, filteredOperations.length - 1),
                  )
                case 'ArrowUp':
                  return setHighlightedIndex((index) =>
                    clamp(index - 1, 0, filteredOperations.length - 1),
                  )
                case 'Escape':
                case 'Tab':
                  e.stopPropagation()
                  return setIsOpen(false)
                case 'Enter': {
                  e.stopPropagation()
                  props.onChange(filteredOperations[highlightedIndex])
                  setIsOpen(false)
                  setInputValue(
                    props.operation
                      ? getOperationName(props.operation) ?? ''
                      : '',
                  )
                  buttonRef.current?.focus()
                  return
                }
              }
            }}
          />
          <SelectList>
            {filteredOperations.map((item, index) => (
              <SelectListItem
                key={getOperationName(item)}
                style={{
                  background:
                    highlightedIndex === index
                      ? 'rgba(255 255 255 /10%)'
                      : 'none',
                }}
                onMouseOver={() => setHighlightedIndex(index)}
                onClickCapture={(e) => {
                  setIsOpen(false)
                  props.onChange(item)
                  buttonRef.current?.focus()
                }}
              >
                {getOperationName(item)}
              </SelectListItem>
            ))}
          </SelectList>
        </SelectContent>
      )}
    </div>
  )
}

const SelectTrigger = styled('button', {
  color: 'inherit',
  fontWeight: 'bold',
  fontSize: 12,
  width: '100%',
  textAlign: 'left',
  padding: '0 16px',
  bordertopRightRadius: 4,
  bordertopLeftRadius: 4,
  height: 40,
  border: '1px solid transparent',
  '&:focus': {
    borderColor: 'var(--parimary-300)',
  },
})

const SelectContent = styled('div', {
  position: 'absolute',
  zIndex: 20,
  transform: 'translateY(-40px)',
  width: '100%',
  height: 320,
  borderRadius: 4,
  display: 'grid',
  gridTemplateCols: '1fr',
  gridTemplateRows: 'auto 1fr',
  background: 'var(--grey-800)',
})

const SelectInput = styled('input', {
  gridColumnStart: 1,
  gridColumnSpan: 2,
  gridRowStart: 1,
  padding: '0 8px',
  width: '100%',
  fontWeight: 'bold',
  fontSize: 12,
  color: 'var(--grey-200)',
  background: 'transparent',
  border: 'none',
  '&:focus': {
    borderBottom: '1px solid var(--perimary-300)',
  },
})
const SelectList = styled('ul', {
  display: 'grid',
  gridAutoRows: '30px',
  overflow: 'auto',
})
const SelectListItem = styled('li', {
  listStyleType: 'none',
  width: '1+00%',
  padding: '0 16px',
  display: 'grid',
  alignItems: 'center',
  cursor: 'pointer',
  color: 'var(--grey-300)',
})
