import {
  ComponentData,
  ComponentModel,
  Project,
} from '../runtime/ComponentModel'
import { applyFormula } from '../runtime/formula/formula'
import { getNode, NodeStyleModel } from '../runtime/NodeModel'
import { isObject, memoize } from '../runtime/util'
import deepEqual from 'fast-deep-equal'

export class DesignCanvas extends HTMLElement {
  _component?: ComponentModel
  _components?: ComponentModel[]
  _project?: Project
  _attrs: Record<string, any>
  _mode: 'design' | 'preview' | 'data'
  iframe: HTMLIFrameElement
  selectionOverlay: HTMLDivElement
  highlightOverlay: HTMLDivElement
  _selectedNodeId: string | undefined
  _highlightedNodeId: string | undefined
  functions: Record<string, (data: ComponentData) => any>
  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    this._mode = 'design'
    this._attrs = {}
    this.functions = {}

    this.iframe = createIframe()
    this.iframe.onload = () => this.init?.()
    this.shadowRoot?.appendChild(this.iframe)

    this.selectionOverlay = createSelectionOverlay()
    this.shadowRoot?.appendChild(this.selectionOverlay)

    this.highlightOverlay = createHighlightOverlay()
    this.shadowRoot?.appendChild(this.highlightOverlay)

    this.addEventListener('click', (e) => {
      if (this._mode === 'preview') {
        return
      }
      e.stopPropagation()
      e.preventDefault()
      const elementId = this.getElementIdFromMousePos(
        { x: e.clientX, y: e.clientY },
        e.metaKey,
      )
      if (typeof elementId === 'string' && elementId !== this._selectedNodeId) {
        this.dispatchEvent(
          new CustomEvent('select', {
            detail: elementId,
            composed: true,
          }),
        )
      }
    })
    this.addEventListener('mousemove', (e) => {
      if (this._mode === 'preview') {
        return
      }
      const elementId = this.getElementIdFromMousePos?.(
        { x: e.clientX, y: e.clientY },
        e.metaKey,
      )
      if (elementId && elementId !== this.highlightedNodeId) {
        this.dispatchEvent(
          new CustomEvent('highlight', {
            detail: elementId,
            composed: true,
          }),
        )
      }
    })
    this.addEventListener('mouseleave', () => {
      if (this._mode === 'preview') {
        return
      }
      this.dispatchEvent(
        new CustomEvent('highlight', {
          detail: undefined,
          composed: true,
        }),
      )
    })

    window.addEventListener('message', (message) => {
      if (message.data.type === 'keydown') {
        this.dispatchEvent(
          new KeyboardEvent('keydown', {
            ...message.data.event,
            bubbles: true,
          }),
        )
      }
      if (message.data.type === 'keyup') {
        this.dispatchEvent(
          new KeyboardEvent('keyup', {
            ...message.data.event,
            bubbles: true,
          }),
        )
      }
      if (message.data.type === 'keypressed') {
        this.dispatchEvent(
          new KeyboardEvent('keypressed', {
            ...message.data.event,
            bubbles: true,
          }),
        )
      }
      if (message.data.type === 'data') {
        this.dispatchEvent(
          new CustomEvent('data', {
            detail: {
              ...message.data.data,
              Functions: this.functions,
            },
            composed: true,
          }),
        )
      }
      if (message.data.type === 'component event') {
        this.dispatchEvent(
          new CustomEvent('event', {
            detail: {
              event: message.data.event,
              time: message.data.time,
              data: message.data.data,
            },
            composed: true,
          }),
        )
      }
    })
  }

  getElementIdFromMousePos(
    mousePos: { x: number; y: number },
    includeTextNodes?: boolean,
  ) {
    if (!this.component) {
      return
    }
    const zoom = 1
    const rect = this.getBoundingClientRect()
    const elements = this.iframe?.contentDocument?.elementsFromPoint(
      (mousePos.x - (rect?.left ?? 0)) / zoom,
      (mousePos.y - (rect?.top ?? 0)) / zoom,
    )
    const elem = elements?.find((elem, i) => {
      const id = elem.getAttribute('data-id')
      if (!id || !this.component) {
        return false
      }

      const node = getNode(this.component.root, id)
      if (!node) {
        return false
      }
      if (node.type === 'text') {
        if (
          includeTextNodes ||
          elements[i + 1]?.getAttribute('data-id') === this.selectedNodeId
        ) {
          return true
        } else {
          return false
        }
      }
      return true
    })
    return elem?.getAttribute('data-id')
  }

  init() {
    // dont render inside an iframe
    // so we dont have infinite recusion when rendering toddle editor :)

    if (getIframeDepth() > 3) {
      return
    }
    // if (!this.iframe.contentDocument?.body || !this.iframe.contentWindow) {
    //   throw new Error('Iframe not ready')
    // }

    // this.iframe.contentDocument.body.id = 'toddle-design-canvas'

    if (this._component) {
      this.iframe.contentWindow?.postMessage({
        type: 'component',
        component: this._component,
      })
    }
    if (this._components) {
      this.iframe.contentWindow?.postMessage({
        type: 'components',
        components: this._components,
      })
    }
    if (this._attrs) {
      this.iframe.contentWindow?.postMessage({
        type: 'attrs',
        attrs: this._attrs,
      })
    }

    if (this._project) {
      this.iframe.contentWindow?.postMessage({
        type: 'project',
        project: this._project,
      })
    }
    this.iframe.contentWindow?.postMessage({
      type: 'mode',
      mode: this._mode,
    })
  }
  onHighlightChange(highlightedNodeId: string | undefined) {
    this._highlightedNodeId = highlightedNodeId

    if (!this.component) {
      return
    }

    if (!highlightedNodeId || highlightedNodeId === this.selectedNodeId) {
      this.highlightOverlay.style.display = 'none'

      return
    }
    const node = getNode(this.component.root, highlightedNodeId)
    const elem = this.iframe?.contentDocument?.querySelector(
      `[data-id="${highlightedNodeId}"]`,
    )
    if (!elem || !node) {
      this.highlightOverlay.style.display = 'none'
      return
    }
    const rect = elem.getBoundingClientRect()
    this.highlightOverlay.style.display = 'block'
    this.highlightOverlay.style.left = `${rect.left}px`
    this.highlightOverlay.style.top = `${rect.top}px`
    this.highlightOverlay.style.width = `${rect.width}px`
    this.highlightOverlay.style.height = `${rect.height}px`

    const nodeTag = this.highlightOverlay.querySelector('div')
    if (nodeTag) {
      nodeTag.innerText =
        node.type === 'element'
          ? node.tag
          : node.type === 'component'
          ? node.name
          : ''
    }
  }
  onSelectionchange(nodeId: string | undefined) {
    this._selectedNodeId = nodeId

    if (!this.component) {
      return
    }
    if (!nodeId) {
      this.selectionOverlay.style.display = 'none'
      return
    }
    if (this.highlightedNodeId === nodeId) {
      this.highlightOverlay.style.display = 'none'
    }

    const elem = this.iframe?.contentDocument?.querySelector(
      `[data-id="${nodeId}"]`,
    )
    const node = getNode(this.component.root, nodeId)
    if (!elem || !node) {
      this.selectionOverlay.style.display = 'none'
      return
    }
    const rect = elem.getBoundingClientRect()
    this.selectionOverlay.style.display = 'block'
    this.selectionOverlay.style.left = `${rect.left}px`
    this.selectionOverlay.style.top = `${rect.top}px`
    this.selectionOverlay.style.width = `${rect.width}px`
    this.selectionOverlay.style.height = `${rect.height}px`
    this.selectionOverlay.style.borderStyle =
      node.type === 'text' ? 'dashed' : 'solid'
    const nodeTag = this.selectionOverlay.querySelector('div')
    if (nodeTag) {
      nodeTag.innerText =
        node.type === 'element'
          ? node.tag
          : node.type === 'component'
          ? node.name
          : ''
    }
  }
  renderOverlay() {}

  set component(component: ComponentModel | undefined) {
    if (deepEqual(component?.functions, this._component?.functions) === false) {
      this.functions = Object.fromEntries(
        component?.functions.map((func) => [
          func.name,
          memoize((data) => applyFormula(func.value, data)),
        ]) ?? [],
      )
    }
    this._component = component
    this.iframe.contentWindow?.postMessage({ type: 'component', component })
  }
  get component() {
    return this._component
  }
  get selectedNodeId() {
    return this._selectedNodeId
  }
  set selectedNodeId(selectedNodeId: string | undefined) {
    if (this._selectedNodeId !== selectedNodeId) {
      this.onSelectionchange(selectedNodeId)
    }
  }
  get highlightedNodeId() {
    return this._highlightedNodeId
  }

  set highlightedNodeId(highlightedNodeId: string | undefined) {
    if (this._highlightedNodeId !== highlightedNodeId) {
      this.onHighlightChange(highlightedNodeId)
    }
  }

  set mode(mode: 'design' | 'preview' | 'data') {
    this._mode = mode
    this.iframe.contentWindow?.postMessage({ type: 'mode', mode })
    if (mode === 'preview') {
      this.selectionOverlay.style.display = 'none'
      this.highlightOverlay.style.display = 'none'
      this.iframe.style.pointerEvents = 'auto'
    } else {
      if (this.selectedNodeId) {
        this.onSelectionchange(this.selectedNodeId)
      }
      if (this.highlightedNodeId) {
        this.onHighlightChange(this.highlightedNodeId)
      }

      this.iframe.style.pointerEvents = 'none'
    }
  }
  get mode() {
    return this._mode
  }
  set attrs(attrs: Record<string, any>) {
    if (isObject(attrs)) {
      this._attrs = attrs
      this.iframe.contentWindow?.postMessage({
        type: 'attrs',
        attrs: this._attrs,
      })
    }
  }
  get attrs() {
    return this._attrs
  }

  set selectedNodeStyle(style: NodeStyleModel) {
    this.onSelectionchange(this.selectedNodeId)
  }
  set project(project: Project | undefined) {
    this._project = project
    if (project?.slug) {
      this.iframe.src = `${window.location.protocol}//${window.location.host}/__preview`
    }
    this.iframe.contentWindow?.postMessage({ type: 'project', project })
  }
  get project() {
    return this._project
  }

  set components(components: ComponentModel[]) {
    this._components = components
    this.iframe.contentWindow?.postMessage({ type: 'components', components })
  }
  get components() {
    return this._components ?? []
  }
}

const getIframeDepth = () => {
  const run = (currentWindow: Window, currentDepths: number): number => {
    if (currentWindow === currentWindow.parent) {
      return currentDepths
    }
    return run(currentWindow.parent, currentDepths + 1)
  }
  return run(window, 0)
}

const createIframe = () => {
  const iframe = document.createElement('iframe')
  iframe.style.border = 'none'
  iframe.style.pointerEvents = 'none'
  iframe.style.width = '100%'
  iframe.style.height = '100%'
  iframe.id = 'toddle-design-canvas'
  iframe.src = `${window.location.protocol}//${window.location.host}/__preview`
  return iframe
}

const createSelectionOverlay = () => {
  const selectionOverlay = document.createElement('div')
  selectionOverlay.style.position = 'absolute'
  selectionOverlay.style.border = '1px solid #ff5877'
  selectionOverlay.style.display = 'none'

  const selectedNodeTag = document.createElement('div')
  selectedNodeTag.style.padding = '2px 4px'
  selectedNodeTag.style.backgroundColor = '#ff5877'
  selectedNodeTag.style.position = 'absolute'
  selectedNodeTag.style.left = '-1px'
  selectedNodeTag.style.top = '0'
  selectedNodeTag.style.fontSize = '12px'
  selectedNodeTag.style.transform = 'translateY(-100%)'
  selectedNodeTag.style.color = '#37050f'
  selectionOverlay.appendChild(selectedNodeTag)
  return selectionOverlay
}
const createHighlightOverlay = () => {
  const highlightOverlay = document.createElement('div')
  highlightOverlay.style.position = 'absolute'
  highlightOverlay.style.boxShadow = '0px 0px 0px 2px #ff5877aa'
  highlightOverlay.style.display = 'none'

  const highlightedNodeTag = document.createElement('div')
  highlightedNodeTag.style.padding = '2px 4px'
  highlightedNodeTag.style.position = 'absolute'
  highlightedNodeTag.style.left = '-1px'
  highlightedNodeTag.style.top = '0'
  highlightedNodeTag.style.fontSize = '12px'
  highlightedNodeTag.style.transform = 'translateY(-100%)'
  highlightedNodeTag.style.color = '#ff5877'
  highlightOverlay.appendChild(highlightedNodeTag)
  return highlightOverlay
}

customElements.define('design-canvas', DesignCanvas, {})
