import React, { useMemo, useRef, useState } from 'react'
import { NodeData } from '../../runtime/ComponentModel'
import {
  applyFormula,
  ValueOperation,
  Formula,
  valueToString,
} from '../../runtime/formula/formula'
import { useEffect } from 'react'
import { styled } from '@stitches/react'

type Props = {
  index: number
  name?: string
  formula: Formula
  onChange: (formula: Formula) => void
  data: NodeData
}

const getItems = (input: string): Array<ValueOperation> => {
  const items: Array<ValueOperation> = []
  if ('true'.includes(input.toLocaleLowerCase())) {
    items.push({
      type: 'value',
      value: true,
    })
  }
  if ('false'.includes(input.toLocaleLowerCase())) {
    items.push({
      type: 'value',
      value: false,
    })
  }
  if (input !== '' && Number.isNaN(Number(input)) === false) {
    items.push({
      type: 'value',
      value: Number(input),
    })
  }
  items.push({
    type: 'value',
    value: input,
  })
  return items
}

export const OperationArgInput = (props: Props) => {
  const isTouched = useRef(false)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState(
    valueToString(applyFormula(props.formula, props.data)),
  )
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  useEffect(() => {
    isTouched.current = false
    setInputValue(valueToString(applyFormula(props.formula, props.data)))
  }, [props.formula])

  const inputRef = useRef<HTMLInputElement>(null)
  const inputItems = useMemo(() => getItems(inputValue ?? ''), [inputValue])
  const highlightedOperation = inputItems[highlightedIndex]
  return (
    <div className="relative">
      {props.name !== undefined && <label>{props.name}</label>}
      <div>
        <ArgInput
          ref={inputRef}
          value={inputValue}
          onChange={(e) => {
            isTouched.current = true
            setInputValue(e.target.value)
          }}
          onClick={(e) => {
            e.nativeEvent.stopPropagation()
          }}
          onBlur={() => {
            if (highlightedOperation && isTouched.current) {
              props.onChange(highlightedOperation)
            }
            setTimeout(() => setIsOpen(false), 100)
          }}
          onFocus={() => setIsOpen(true)}
          onKeyDown={(e) => {
            switch (e.key) {
              case 'ArrowDown':
                isTouched.current = true
                setHighlightedIndex((highlightedIndex) =>
                  Math.min(inputItems.length - 1, highlightedIndex + 1),
                )
                break
              case 'ArrowUp':
                isTouched.current = true
                setHighlightedIndex((highlightedIndex) =>
                  Math.max(0, highlightedIndex - 1),
                )
                break
              case 'Enter':
                if (highlightedOperation) {
                  props.onChange(highlightedOperation)
                }
                inputRef.current?.blur()
                break
              case 'Tab': {
                if (highlightedOperation && isTouched.current) {
                  props.onChange(highlightedOperation)
                }
                inputRef.current?.blur()
                break
              }
              case 'Escape': {
                setIsOpen(false)
                break
              }
            }
          }}
        />
      </div>
      <SuggestionList data-open={isOpen} onClick={(e) => e.stopPropagation()}>
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              data-red
              key={`${item}${index}`}
              aria-selected={highlightedIndex === index}
              onClick={() => props.onChange(item)}
              style={{
                height: 50,
                padding: '0 8px',
                alignContent: 'center',
                color:
                  highlightedIndex === index
                    ? 'var(--grey-100)'
                    : 'var(--grey-400)',
                background:
                  highlightedIndex === index
                    ? 'var(--grey-600)'
                    : 'transparent',

                fontSize: highlightedIndex === index ? 18 : 14,
              }}
            >
              <div
                style={{
                  color: 'var(--grey-400)',
                  fontSize: 12,
                }}
              >
                {item === null ? 'Null' : typeof item.value}
              </div>
              {String(item.value)}
            </li>
          ))}
      </SuggestionList>
    </div>
  )
}

const ArgInput = styled('input', {
  width: '100%',
  background: 'var(--grey-700)',
  padding: '0 8px',
  borderRadius: 4,
  color: 'var(--grey-200)',
  border: '1px solid transparent',
  '&:focus': {
    borderColor: 'var(--parimary-300)',
  },
})

const SuggestionList = styled('ul', {
  position: 'absolute',
  width: '100%',
  background: 'var(--grey-700)',
  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.4)',
  zIndex: 10,
})
