import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Formula } from '../../runtime/formula/formula'

import { ResultNode } from './Operation'
import { NodeData } from '../../runtime/ComponentModel'
import { useZoomPlane } from './useZoomPlane'
import { useKey } from './useKey'
import useResizeObserver from './useResizeObserver'

import * as Dialog from '@radix-ui/react-dialog'
import { styled } from '@stitches/react'

const moveExpression = (
  formula: Formula | undefined,
  oldId: string,
  newId: string,
) => {
  if (!formula) {
    return
  }
  const oldPath = oldId.split('.').map(Number).slice(1)
  const newPath = newId.split('.').map(Number).slice(1)
  const findExp = (
    formula: Formula,
    [current, ...path]: number[],
  ): Formula | undefined => {
    if (formula.type !== 'function') {
      return
    }
    if (path.length === 0) {
      return formula.arguments[current]?.formula
    }
    if (formula.arguments[current]?.formula) {
      return findExp(formula.arguments[current].formula, path)
    } else {
      return undefined
    }
  }
  const removeExp = (
    formula: Formula,
    [current, ...path]: number[],
  ): Formula => {
    if (formula.type !== 'function') {
      return formula
    }
    if (path.length === 0) {
      return {
        ...formula,
        arguments: [
          ...formula.arguments.slice(0, current),
          {
            ...formula.arguments[current],
            formula: { type: 'value', value: null },
          },
          ...formula.arguments.slice(current + 1),
        ],
      }
    }
    return {
      ...formula,
      arguments: formula.arguments.map((a, i) =>
        i === current ? { ...a, formula: removeExp(a.formula, path) } : a,
      ),
    }
  }

  const insertExp = (
    formula: Formula,
    [current, ...path]: number[],
    expression: Formula,
  ): Formula => {
    if (formula.type !== 'function') {
      return formula
    }
    if (path.length === 0) {
      return {
        ...formula,
        arguments: [
          ...formula.arguments.slice(0, current),
          {
            ...formula.arguments[current],
            formula: expression,
          },
          ...formula.arguments.slice(current + 1),
        ],
      }
    }
    return {
      ...formula,
      arguments: formula.arguments.map((a, i) =>
        i === current
          ? { ...a, formula: insertExp(a.formula, path, expression) }
          : a,
      ),
    }
  }

  const exp = findExp(formula, oldPath)
  if (exp) {
    return insertExp(removeExp(formula, oldPath), newPath, exp)
  } else {
    return formula
  }
}

type FormulaEditButtonProps = {
  formula?: Formula
  input: any
  onChange: (formula?: Formula) => void
  disabled?: boolean
  size?: number
  title?: string
  style?: CSSProperties
  className?: string
}

export const FormulaEditButton = (props: FormulaEditButtonProps) => {
  const { title = 'Edit Formula' } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        e.stopPropagation()
        if (e.key === 'Escape') {
          setIsOpen(false)
        }
        if (e.key !== 'z') {
          e.stopPropagation()
        }
      }}
      className={props.className}
      style={props.style}
    >
      <Dialog.Root
        open={props.input && isOpen}
        onOpenChange={(isOpen) => setIsOpen(isOpen)}
      >
        <DialogTrigger
          onClick={(e) => e.stopPropagation()}
          style={{
            background: 'none',
            border: 'none',
            color:
              props.formula && props.formula.type !== 'value'
                ? 'var(--primary-300)'
                : 'var(--grey-300)',
            fontStyle: 'italic',
          }}
        >
          fx
        </DialogTrigger>
        <Dialog.Overlay />
        <DialogContent>
          <header
            style={{
              height: '40px',
              background: 'var(--grey-800)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 16px',
              color: 'var(--text-grey-200)',
            }}
          >
            {title}
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <button
                onClick={() => {
                  props.onChange(undefined)
                }}
              >
                Clear
              </button>
              <Dialog.Close>Close</Dialog.Close>
            </div>
          </header>
          <FormulaEditor
            formula={props.formula}
            onChange={props.onChange}
            data={props.input}
          />
        </DialogContent>
      </Dialog.Root>
    </div>
  )
}
const DialogTrigger = styled(Dialog.Trigger, {
  border: '1px solid transparent',
  borderRadius: '4px',
  color: 'var(--grey-200)',
  fontSize: '12px',
  '&:focus': {
    borderColor: 'var(--primary-300',
  },
})

const DialogContent = styled(Dialog.Content, {
  width: '100%',
  height: '100%',
  position: 'fixed',
  borderRadius: '0',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gridTemplateCols: '1fr',
  background: 'var(--grey-700)',
})

type Props = {
  formula: Formula | undefined
  onChange: (formula: Formula | undefined) => void
  onHide: () => void
  data: NodeData
}

export const FormulaEditor = (props: Omit<Props, 'onHide'>) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const ref = useRef<HTMLDivElement>(null)
  const spaceKey = useKey(' ')

  useEffect(() => {
    setTimeout(() => ref.current?.focus(), 100)
  }, [])
  useZoomPlane({
    ref,
    onPan: useCallback(
      ({ x, y }) => {
        setOffset((offset) => ({
          x: offset.x + x,
          y: offset.y + y,
        }))
      },
      [setOffset],
    ),
  })

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        borderTop: '2px solid var(--grey-800)',
        cursor: spaceKey ? 'grab' : 'default',
      }}
      ref={ref}
      tabIndex={-1}
      id="container"
    >
      <div
        style={{
          padding: '32px',
          minHeight: '100%',
          minWidth: '100%',
          left: 0,
          top: 0,
          position: 'absolute',
          transformOrigin: 'center',
          display: 'grid',
          placeContent: 'center',
          background: 'var(--grey-700)',
          transform: `translate(${offset.x}px, ${offset.y}px)`,
        }}
      >
        <Lines check={props.formula} />

        <ResultNode
          operation={props.formula ?? { type: 'path', path: [] }}
          path="0"
          onChange={props.onChange}
          data={props.data}
        />
      </div>
    </div>
  )
}

export type Point = { path: string; x: number; y: number }

export const Line = (props: { from: Point; to: Point }) => {
  const { from, to } = props
  const curve = Math.max(
    Math.abs(from.x - to.x) * 0.5,
    Math.abs(from.y - to.y) / 5,
  )

  return (
    <path
      style={{
        stroke: 'var(--grey-500)',
        strokeWidth: '3px',
      }}
      fill="none"
      shapeRendering="optimizeQuality"
      d={`M ${from.x} ${from.y} C ${from.x + curve} ${from.y}, ${
        to.x - curve
      } ${to.y}, ${to.x} ${to.y}`}
    />
  )
}

export const Lines = (props: { check: any }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { width, height } = useResizeObserver(ref)
  const [lines, setLines] = useState<{ from: Point; to: Point }[]>([])

  useLayoutEffect(() => {
    setLines([])
    document.querySelectorAll('[data-node-output]').forEach((elem) => {
      const path = elem.getAttribute('data-node-output')
      const parentRect = ref.current?.getBoundingClientRect()
      const rect = elem.getBoundingClientRect()
      const targetRect = document
        .querySelector(`[data-node-input="${path}"]`)
        ?.getBoundingClientRect()
      if (!path || !rect || !targetRect || !parentRect) {
        return
      }
      setLines((lines) => [
        ...lines,
        {
          from: {
            path,
            x: rect.left + rect.width / 2 - parentRect.left,
            y: rect.top + rect.height / 2 - parentRect.top,
          },
          to: {
            path,
            x:
              targetRect.left +
              targetRect.width / 2 -
              targetRect.width / 2 -
              parentRect.left,
            y: targetRect.top + targetRect.height / 2 - parentRect.top,
          },
        },
      ])
    })
  }, [props.check, width, height])

  return (
    <div
      ref={ref}
      style={{ position: 'absolute', width: '100%', height: '1000%' }}
    >
      <svg
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {lines.map((line, i) => (
          <Line key={i} {...line} />
        ))}
      </svg>
    </div>
  )
}
